<div class="alain-default__progress-bar" *ngIf="isFetching"></div>
<layout-header class="alain-default__header" *ngIf="!installationMode"></layout-header>
<layout-navbar *ngIf="!installationMode"></layout-navbar>
<!-- <layout-sidebar class="alain-default__aside" #layoutSidebar *ngIf="!installationMode"></layout-sidebar> -->
<!-- <layout-sidebar class="alain-default__aside" #layoutSidebar></layout-sidebar> -->
<!-- <section [className]="installationMode ? 'alain-fullscreen' : 'alain-default__content'"> -->
<section class="alain-fullscreen">
    <router-outlet></router-outlet>
    <!-- <div class="nav-reutab-custom" style="padding-left: 15px;">

        <reuse-tab [mode]="2" #reuseTab></reuse-tab>
    </div> -->

    <!-- <reuse-tab [mode]="2" (close)="tabClose($event)" (change)="tabChange($event)" #reuseTab></reuse-tab>
    <router-outlet (activate)="reuseTab.activate($event)"></router-outlet> -->
</section>
<ng-template #settingHost></ng-template>
<nz-back-top></nz-back-top>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>

<!-- <div class="nav-reutab-custom" style="padding-left: 15px;">

    <reuse-tab [mode]="2" #reuseTab></reuse-tab>
    <router-outlet (activate)="reuseTab.activate($event)"></router-outlet>
</div> -->
<!--<layout-theme-btn></layout-theme-btn>-->

<nz-modal
    [(nzVisible)]="isShowThongBao"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="modalFooter"
    (nzOnCancel)="close()"
    [nzWidth]="700"
>
    <ng-template #modalTitle>Thông báo</ng-template>
    <ng-template #modalContent>
        <h4 class="text-center">{{ tieuDe }}</h4>
        <div class="xem-thong-bao mb-2">
            <span [innerHTML]="convertTextAreaToHtml(noiDung)"></span>
            <div *ngIf="duongDanFile" class="mt-2">
                <a href="javascript:void(0)" (click)="xemFile(duongDanFile)"><em>Xem tài liệu đính kèm</em></a>
            </div>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <label class="float-left" nz-checkbox [(ngModel)]="isDaDoc">Đánh dấu là đã đọc thông báo</label>
        <button nz-button nzType="default" (click)="close()"><span nz-icon nzType="close" nzTheme="outline"></span> Đóng</button>
    </ng-template>
</nz-modal>
