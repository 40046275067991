import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
    //{
    //    path: '',
    //    loadChildren: () => import('./_website/website.module').then(m => m.WebsiteModule), // Lazy load account module
    //    data: { preload: true },
    //},
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule), // Lazy load account module
        data: { preload: true },
    },
    //{ path: '**', redirectTo: '/app/main/dashboard' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule { }
