import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Tổng quan', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/hostDashboard'),
            new AppMenuItem('Tổng quan', 'Pages.Tenant.Dashboard', 'dashboard', '/app/main/dashboard'),
            new AppMenuItem('Thông tin cơ sở', 'Pages.ThongTinCoSo', 'reconciliation', '/app/du-lieu-so-yte/thong-tin-co-so'),
            new AppMenuItem('Đăng ký cập nhật nhân sự Y', 'Pages.HoSo98.TaoMoi', 'file', '/app/thu-tuc-hanh-chinh/thu-tuc-98/danh-sach-ho-so-moi'),
            new AppMenuItem('Đăng ký cập nhật nhân sự Dược', 'Pages.HoSo97.TaoMoi', 'file', '/app/thu-tuc-hanh-chinh/thu-tuc-97/danh-sach-ho-so-moi'),

            new AppMenuItem('Quản lý hồ sơ', 'Pages.ThuTucHanhChinh', 'snippets', '', [
                new AppMenuItem('Cấp lần đầu chứng chỉ hành nghề', 'Pages.HoSoHanhNghe', 'file-text', '/app/thu-tuc-hanh-chinh/ho-so-hanh-nghe/lua-chon-chuc-nang'),
                new AppMenuItem('Cập nhật nhân sự Y', 'Pages.HoSo98', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-98/lua-chon-chuc-nang'),
                new AppMenuItem('Cập nhật nhân sự Dược', 'Pages.HoSo97', 'file-text', '/app/thu-tuc-hanh-chinh/thu-tuc-97/lua-chon-chuc-nang'),
                //new AppMenuItem('Thủ tục mẫu', 'Pages.HoSoMau', 'file-text', 'app/thu-tuc-hanh-chinh/thu-tuc-mau/lua-chon-chuc-nang-mau'),
            ]),
            // new AppMenuItem('Kết quả cập nhật nhân sự', 'Pages.TruyenThongNhanSu', 'file', '/app/truyen-thong-nhan-su/danh-sach-ho-so'),

            new AppMenuItem('Cơ sở hành nghề y', 'Pages.DuLieuCoSoYte.Y', 'reconciliation', '/app/du-lieu-so-yte/co-so-cap-phep'),
            new AppMenuItem('Cơ sở hành nghề dược', 'Pages.DuLieuCoSoYte.Duoc', 'home', '/app/du-lieu-so-yte/co-so-duoc'),
            new AppMenuItem('Yêu cầu cập nhật thông tin', 'Pages.DuLieuCoSoYte.DuyetThayDoi', 'bars', '', [
                new AppMenuItem('Cơ sở Dược', 'Pages.DuLieuCoSoYte.DuyetThayDoi.Duoc', 'file', '/app/du-lieu-so-yte/thong-tin-co-so/co-so-duoc/duyet-thay-doi-thong-tin'),
                new AppMenuItem('Cơ sở Y', 'Pages.DuLieuCoSoYte.DuyetThayDoi.Y', 'file', '/app/du-lieu-so-yte/thong-tin-co-so/co-so-y/duyet-thay-doi-thong-tin'),
            ]),
            new AppMenuItem('Cơ sở đăng ký tài khoản', 'Pages.QuanLyTaiKhoanCoSo', 'user-add', '/app/quan-ly-tai-khoan-co-so/danh-sach-tai-khoan-co-so-dang-ky', [
                // new AppMenuItem('Danh sách tài khoản cơ sở đăng ký', 'Pages.QuanLyTaiKhoanCoSo.DanhSachTaiKhoanCoSoDangKy', 'user-add', '/app/quan-ly-tai-khoan-co-so/danh-sach-tai-khoan-co-so-dang-ky'),
            ]),

            new AppMenuItem('Tra cứu thông tin', 'Pages.TraCuuThongTin', 'funnel-plot', '/app/tra-cuu/tra-cuu-thong-tin', []),

            new AppMenuItem('Báo cáo', 'Pages.BaoCao', 'file', '', [
                new AppMenuItem('Báo cáo nhân lực', 'Pages.BaoCao.BaoCaoNhanLuc', 'file', '/app/bao-cao/bao-cao-nhan-luc'),
            ]),

            new AppMenuItem('Quản lý công văn', 'Pages.QuanLyCongVan', 'snippets', '', [
                new AppMenuItem('Tra cứu công văn', 'Pages.QuanLyCongVan.TraCuuCongVan', 'file', '/app/quan-ly-cong-van/tra-cuu-cong-van'),
                new AppMenuItem('Xác minh thông tin hành nghề y', 'Pages.QuanLyCongVan.TraCuuCongVanTraLoiXacMinhHanhNghe', 'file', '/app/quan-ly-cong-van/cong-van-xac-minh-hanh-nghe'),
            ]),

            new AppMenuItem('Danh mục chuyên môn', 'Pages.DanhMucChuyenMon', 'bars', '', [
                new AppMenuItem('Hình thức tổ chức', 'Pages.DanhMucChuyenMon.HinhThucToChuc', 'file', '/app/danh-muc-chuyen-mon/danh-muc-hinh-thuc-to-chuc'),
                new AppMenuItem('Phạm vi cơ sở', 'Pages.DanhMucChuyenMon.PhamViHoatDong', 'file', '/app/danh-muc-chuyen-mon/danh-muc-pham-vi-hoat-dong-co-so'),
                new AppMenuItem('Phạm vi người hành nghề', 'Pages.DanhMucChuyenMon.PhamViChuyenMon', 'file', '/app/danh-muc-chuyen-mon/danh-muc-pham-vi-hoat-dong-nguoi'),
                new AppMenuItem('Văn bằng chuyên môn', 'Pages.DanhMucChuyenMon.VanBangChuyenMon', 'file', '/app/danh-muc-chuyen-mon/danh-muc-van-bang-chuyen-mon'),
                new AppMenuItem('Chức danh nghề nghiệp', 'Pages.DanhMucChuyenMon.ChucDanhNgheNghiep', 'file', '/app/danh-muc-chuyen-mon/danh-muc-chuc-danh-nghe-nghiep'),
                new AppMenuItem('Cơ sở y tế công lập', 'Pages.DanhMucChuyenMon.CoSoCongLap', 'file', '/app/danh-muc-chuyen-mon/danh-muc-co-so-cong-lap'),
                //new AppMenuItem('Cơ sở đủ điều kiện', 'Pages.DanhMucChuyenMon.CoSoDuDieuKien', 'file', '/app/danh-muc-chuyen-mon/danh-muc-co-so-du-dieu-kien'),
                new AppMenuItem('Thời gian làm việc', 'Pages.DanhMucChuyenMon.ThoiGianLamViec', 'file', '/app/danh-muc-chuyen-mon/danh-muc-thoi-gian-lam-viec-trong-ngay'),
                new AppMenuItem('Khoa phòng', 'Pages.DanhMucChuyenMon.KhoaPhong', 'file', '/app/danh-muc-chuyen-mon/danh-muc-khoa-phong'),
                new AppMenuItem('Lý do thường gặp khi duyệt', 'Pages.DanhMucChuyenMon.LyDoThuongGapKhiDuyet', 'file', '/app/danh-muc-chuyen-mon/danh-muc-ly-do-thuong-gap-khi-duyet'),
            ]),

            new AppMenuItem('Chứng chỉ hành nghề', 'Pages.ChungChiHanhNghe', 'audit', '', [
                new AppMenuItem('Chứng chỉ hành nghề y', 'Pages.ChungChiHanhNghe.ChungChiHanhNgheY', 'file', '/app/chung-chi-hanh-nghe/chung-chi-hanh-nghe-y'),
                new AppMenuItem('Chứng chỉ hành nghề dược', 'Pages.ChungChiHanhNghe.ChungChiHanhNgheDuoc', 'file', '/app/chung-chi-hanh-nghe/chung-chi-hanh-nghe-duoc'),
            ]),

            // new AppMenuItem('Thủ tục cập nhật nhân sự', 'Pages.ThuTucHanhChinh', 'snippets', 'Pages.ThuTucCapNhatNhanSu', [
            //     new AppMenuItem('Nộp hồ sơ', 'Pages.ThuTucCapNhatNhanSu.NopHoSo', 'file', '/app/thu-tuc-cap-nhat-nhan-su/danh-sach-ho-so'),
            // ]),

            // new AppMenuItem('Quản lý tờ trình', 'Pages.QuanLyToTrinh', 'file-done', '', [
            //     new AppMenuItem('Công việc cần xử lý', 'Pages.QuanLyToTrinh.CongViec', 'check-circle', '/app/quan-ly-to-trinh/cong-viec'),
            //     new AppMenuItem('Văn bản đến', 'Pages.QuanLyToTrinh.VanBanDen', 'check-circle', '/app/quan-ly-to-trinh/van-ban-den'),
            //     new AppMenuItem('Văn bản chờ phân công', 'Pages.QuanLyToTrinh.VanBanChoDuyet', 'check-circle', '/app/quan-ly-to-trinh/van-ban-cho-duyet'),
            //     new AppMenuItem('Văn bản chờ tư vấn', 'Pages.QuanLyToTrinh.VanBanTuVan', 'check-circle', '/app/quan-ly-to-trinh/van-ban-cho-tu-van'),
            //     new AppMenuItem('Văn bản chờ phân công', 'Pages.QuanLyToTrinh.VanBanChoXuLy', 'check-circle', '/app/quan-ly-to-trinh/van-ban-cho-phan-cong'),
            //     new AppMenuItem('Biên soạn tờ trình', 'Pages.QuanLyToTrinh.BienSoanToTrinh', 'check-circle', '/app/quan-ly-to-trinh/van-ban-bien-soan'),
            //     new AppMenuItem('Tờ trình chờ duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoPhoPhongDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-pho-phong-duyet'),
            //     new AppMenuItem('Tờ trình chờ duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoTruongPhongDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-truong-phong-duyet'),
            //     new AppMenuItem('Tiếp nhận tờ trình chờ góp ý', 'Pages.QuanLyToTrinh.ToTrinhChoGopY', 'check-circle', '/app/quan-ly-to-trinh/van-ban-phong-ban-gop-y'),
            //     new AppMenuItem('Tờ trình chờ góp ý', 'Pages.QuanLyToTrinh.ToTrinhChoGopYChuyenVien', 'check-circle', '/app/quan-ly-to-trinh/van-ban-phong-ban-chuyen-vien-gop-y'),
            //     new AppMenuItem('Tờ trình chờ phê duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoPhoGiamDocPheDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-pho-giam-doc-phe-duyet'),
            //     new AppMenuItem('Tờ trình chờ phê duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoGiamDocPheDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-giam-doc-phe-duyet'),
            //     new AppMenuItem('Tờ trình chờ tư vấn', 'Pages.QuanLyToTrinh.ToTrinhChoTuVan', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-tu-van'),
            //     new AppMenuItem('Tờ trình đã phê duyệt', 'Pages.QuanLyToTrinh.ToTrinhDaPheDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-da-phe-duyet'),
            //     new AppMenuItem('Tờ trình chờ ban hành', 'Pages.QuanLyToTrinh.ToTrinhBanHanh', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-ban-hanh'),
            //     new AppMenuItem('Văn bản Admin quản lý', 'Pages.QuanLyToTrinh.QuyenAdminXuLy', 'check-circle', '/app/quan-ly-to-trinh/van-ban-admin-quan-ly'),
            // ]),

            // new AppMenuItem('Quản lý lịch họp', 'Pages.QuanLyLichHop', 'comment', '', [
            //     new AppMenuItem('Lịch họp mới', 'Pages.QuanLyLichHop.LichHopMoi', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-moi'),
            //     new AppMenuItem('Lịch họp chờ duyệt', 'Pages.QuanLyLichHop.LichHopChoDuyet', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-duyet'),
            //     new AppMenuItem('Lịch họp chờ góp ý', 'Pages.QuanLyLichHop.LichHopChoGopY', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-gop-y'),
            //     new AppMenuItem('Lịch họp chờ tổng hợp', 'Pages.QuanLyLichHop.LichHopChoTongHop', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-tong-hop'),
            //     new AppMenuItem('Lịch họp chờ kết luận', 'Pages.QuanLyLichHop.LichHopChoKetLuan', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-ket-luan'),
            //     // new AppMenuItem('Lịch họp chờ tham gia', 'Pages.QuanLyLichHop.LichHopChoThamGia', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-tham-gia'),
            //     // new AppMenuItem('Lịch họp đang diễn ra', 'Pages.QuanLyLichHop.LichHopDangDienRa', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-dang-dien-ra'),
            //     // new AppMenuItem('Lịch họp đã hoàn thành', 'Pages.QuanLyLichHop.LichHopDaHoanThanh', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-da-hoan-thanh'),
            // ]),

            // new AppMenuItem('Ký điện tử', 'Pages.KyDienTu', 'edit', '', [
            //     new AppMenuItem('Văn bản trình ký', 'Pages.KyDienTu.VanBanTrinhKy', 'check-circle', '/app/ky-dien-tu/van-ban-trinh-ky'),
            //     new AppMenuItem('Văn bản xét duyệt', 'Pages.KyDienTu.VanBanXetDuyet', 'check-circle', '/app/ky-dien-tu/van-ban-xet-duyet'),
            //     new AppMenuItem('Văn bản ký duyệt', 'Pages.KyDienTu.VanBanKyDuyet', 'check-circle', '/app/ky-dien-tu/van-ban-ky-duyet'),
            //     new AppMenuItem('Văn bản ký nháy', 'Pages.KyDienTu.VanBanKyNhay', 'check-circle', '/app/ky-dien-tu/van-ban-ky-nhay'),
            //     new AppMenuItem('Văn bản ban hành', 'Pages.KyDienTu.VanBanBanHanh', 'check-circle', '/app/ky-dien-tu/van-ban-ban-hanh'),
            //     new AppMenuItem('Quy trình ký mẫu', 'Pages.KyDienTu.QuyTrinhKyMau', 'setting', '/app/ky-dien-tu/quy-trinh-ky-mau'),
            // ]),

            // new AppMenuItem('Thư viện văn bản', 'Pages.ThuVienVanBan', 'container', '', [
            //     new AppMenuItem('Công bố văn bản', 'Pages.ThuVienVanBan.CongBoVanBan', 'check-circle', '/app/thu-vien-van-ban/cong-bo-van-ban'),
            //     new AppMenuItem('Tra cứu văn bản', 'Pages.ThuVienVanBan.TraCuuVanBan', 'check-circle', '/app/thu-vien-van-ban/tra-cuu-van-ban'),
            // ]),
            //new AppMenuItem('Hội đồng thẩm định', 'Pages.ToThuKy', 'team', '/app/to-thu-ky/danh-sach-to-thu-ky', []),
            new AppMenuItem('Danh mục hành chính', 'Pages.DanhMuc', 'bars', '', [

                new AppMenuItem('Tỉnh / thành phố', 'Pages.DanhMuc.Tinh', 'environment', '/app/danh-muc/tinh'),
                new AppMenuItem('Quận / huyện', 'Pages.DanhMuc.Huyen', 'environment', '/app/danh-muc/huyen'),
                new AppMenuItem('Phường / xã', 'Pages.DanhMuc.Xa', 'environment', '/app/danh-muc/xa'),
                new AppMenuItem('Phòng ban', 'Pages.DanhMuc.PhongBan', 'block', '/app/danh-muc/phong-ban'),
                new AppMenuItem('Lịch làm việc Sở Y tế', 'Pages.DanhMuc.LichLamViec', 'calendar', '/app/danh-muc/lich-lam-viec'),
                // new AppMenuItem('Địa chính', 'Pages.DanhMuc.DiaChinh', 'environment', '', [
                //     //new AppMenuItem('Quốc gia', 'Pages.DanhMuc.QuocTich', 'environment', '/app/danh-muc/quoc-gia'),
                // ]),

                //new AppMenuItem('Cây danh mục', 'Pages.DanhMuc.CayDanhMuc', 'check-circle', '/app/danh-muc/danh-muc-chung'),
                //new AppMenuItem('Chức vụ', 'Pages.DanhMuc.ChucVu', 'team', '/app/danh-muc/chuc-vu'),
            ]),

            new AppMenuItem('Lọc khoa phòng', 'Pages.HeThong', 'funnel-plot', '/app/loc-khoa-phong-benh-vien/loc-khoa-phong-benh-vien'),


            new AppMenuItem('Hệ thống', 'Pages.HeThong', 'tool', '', [
                //new AppMenuItem('Cài đặt', 'Pages.HeThong.CaiDat', 'setting', '/app/he-thong/cai-dat'),
                new AppMenuItem('Tài khoản người dùng', 'Pages.HeThong.QuanLyTaiKhoan', 'user', '/app/he-thong/tai-khoan-nguoi-dung'),
                new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HeThong.TaiLieuHuongDan', 'book', '/app/he-thong/tai-lieu-huong-dan'),
                // new AppMenuItem('Thiết lập chữ ký số', 'Pages.HeThong.ThietLapChuKySo', 'setting', '/app/he-thong/thiet-lap-chu-ky-so'),
                //new AppMenuItem('Thiết lập chung', 'Pages.HeThong.ThietLapChung', 'setting', '/app/he-thong/thiet-lap-chung'),
                //new AppMenuItem('Thiết lập domain', 'Pages.HeThong.DomainRoute', 'setting', '/app/he-thong/thiet-lap-domain'),
                //new AppMenuItem('Danh sách gửi mail', 'Pages.HeThong.DanhSachGuiMail', 'book', '/app/he-thong/danh-sach-gui-mail'),
            ]),



            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [
                //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                //new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                new AppMenuItem('Nhật ký hệ thống', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                //new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings')
            ]),

            // new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),
            new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HuongDanSuDung', 'check-circle', '/app/he-thong/xem-huong-dan-su-dung'),
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
