import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfKetQuaComponent implements OnInit {
  @Input() path;
  constructor(protected sanitizer: DomSanitizer) {}

  ngOnInit(): void {
  }

  getPath(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.path)
  }

  openTaiLieuDangKyTabMoi() {
    window.open(this.path, '_blank');
  }
}
