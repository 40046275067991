import { AfterContentInit, AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@delon/theme';

@Component({
    selector: 'layout-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements AfterViewInit {
    @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
    arr: any[] = [];
    constructor(public settingsSrv: SettingsService,) {
    }

    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }

    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }

    ngAfterViewInit(): void {
        let sidebarNav = this.widgetsContent.nativeElement.querySelector('.sidebar-nav');

        ///event click Button Left
        let buttonLeft = document.querySelector('.nav_button_left');
        buttonLeft.addEventListener('click', () => {
            sidebarNav.scrollTo({ left: (sidebarNav.scrollLeft - 550) }); ``
        })

        let buttonRight = document.querySelector('.nav_button_right');
        let bRigth = buttonRight as HTMLElement;
        if (sidebarNav.offsetWidth + sidebarNav.scrollLeft >= sidebarNav.scrollWidth) {
            bRigth.style.display = 'none';
        } else {
            bRigth.style.display = 'inline-block';
        }
        ///event click Button Right
        buttonRight.addEventListener('click', () => {
            sidebarNav.scrollTo({ left: (sidebarNav.scrollLeft + 550) });
        })

        /// đổi vị trí của dropdown
        let listNav = sidebarNav.querySelectorAll('.sidebar-nav__item');
        listNav.forEach(item => {
            item.addEventListener('mouseover', (e) => {
                let parentNav = item.querySelector('.sidebar-nav');

                let lastChildNav = item.querySelector('.sidebar-nav li:last-child ul');
                if (parentNav) {
                    item.querySelector('.sidebar-nav').style.left = (item.offsetLeft - sidebarNav.scrollLeft) + 'px';
                    let listChildNav = parentNav.querySelectorAll('.sidebar-nav__item');
                    let maxValue = Math.max(...[...listChildNav].map(e => e.offsetWidth));
                    if (maxValue > 230) {
                        parentNav.style.width = maxValue + 'px';
                    }
                }
                if (lastChildNav) {
                    item.querySelector('.sidebar-nav').style.left = ((item.offsetLeft - sidebarNav.scrollLeft) - 80) + 'px';
                    if (sidebarNav.offsetWidth >= sidebarNav.scrollWidth) {
                        item.querySelector('.sidebar-nav').style.left = (item.offsetLeft - sidebarNav.scrollLeft) + 'px';
                    }
                }
            })
        });

        ///event scroll
        sidebarNav.addEventListener('scroll', (event) => {
            /// event ẩn button right
            let bRigth = buttonRight as HTMLElement;
            if (event.target.offsetWidth + event.target.scrollLeft + 0.5 >= event.target.scrollWidth) {
                bRigth.style.visibility = 'hidden';
            } else {
                bRigth.style.display = 'inline-block';
                bRigth.style.visibility = 'unset';
            }
            /// event ẩn button left
            let bLeft = buttonLeft as HTMLElement;
            if (event.target.scrollLeft > 0) {
                bLeft.style.display = 'inline-block';
                bLeft.style.visibility = 'unset';
            } else {
                bLeft.style.visibility = 'hidden';
            }
        })

        ///
        window.addEventListener('resize', (event) => {
            let bRigth = buttonRight as HTMLElement;
            if (sidebarNav.offsetWidth + sidebarNav.scrollLeft >= sidebarNav.scrollWidth) {
                bRigth.style.display = 'none';
            } else {
                bRigth.style.display = 'inline-block';
                bRigth.style.visibility = 'unset';
            }
        })


    }
}
