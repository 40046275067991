<div class="alain-default__header-logo" style="width: auto;">
    <!-- <a class="mobile-menu-toggle" (click)="toggleMenu.emit(!isToggleMenu)">
        <i nz-icon nzType="menu" nzTheme="outline"></i>
    </a> -->
    <!-- <a target="_blank" class="alain-default__header-logo-link">
         <img class="alain-default__header-logo-expanded" src="{{logoImage}}" alt="{{settings.app.name}}" style="max-height:40px;" />
    </a> -->
</div>
<div class="alain-default__nav-wrap">
    <ul class="alain-default__nav">
        <li>
            <img src="{{logoImage}}" alt="{{settings.app.name}}" style="max-height:50px;" />
        </li>
        <li style="font-weight: bold; text-transform: uppercase">
            <h1 class="title-he-thong">Hệ thống quản lý hành nghề y - dược</h1>
            <p class="title-don-vi" *ngIf="!appSession.user.coSoId">Sở y tế Hà Nội</p>
            <p class="title-don-vi" *ngIf="appSession.user.coSoId">{{appSession.user.tenCoSo}}</p>
        </li>
    </ul>
    <ul class="alain-default__nav">
        <li *ngIf="isImpersonatedLogin">
            <button (click)="backToMyAccount()" nz-button nzType="link" title="Quay lại tài khoản chính">
                <i nz-icon nzType="api" nzTheme="outline"></i>
            </button>
        </li>
        <li class="hidden-mobile">
            <header-user style="float: left;"></header-user>
        </li>
    </ul>
</div>