import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';
import { WelcomeComponent } from './shared/layout/welcome/welcome.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: 'welcome', component: WelcomeComponent },
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then(m => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc',
                loadChildren: () => import('./pages/danh-muc/danh-muc.module').then(m => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'he-thong',
                loadChildren: () => import('./pages/he-thong/he-thong.module').then(m => m.HeThongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'ky-dien-tu',
                loadChildren: () => import('./pages/ky-dien-tu/ky-dien-tu.module').then(m => m.KyDienTuModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-to-trinh',
                loadChildren: () => import('./pages/quan-ly-to-trinh/quan-ly-to-trinh.module').then(m => m.QuanLyToTrinhModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-lich-hop',
                loadChildren: () => import('./pages/quan-ly-lich-hop/quan-ly-lich-hop.module').then(m => m.QuanLyLichHopModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-vien-van-ban',
                loadChildren: () => import('./pages/thu-vien-van-ban/thu-vien-van-ban.module').then(m => m.ThuVienVanBanModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },

            {
                path: 'du-lieu-so-yte',
                loadChildren: () => import('./pages/du-lieu-so-yte/du-lieu-so-y-te.module').then(m => m.DuLieuSoYTeModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc-chuyen-mon',
                loadChildren: () => import('./pages/danh-muc-chuyen-mon/danh-muc-chuyen-mon.module').then(m => m.DanhMucChuyenMonModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/ho-so-hanh-nghe',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/ho-so-cap-phep-hanh-nghe/ho-so-hanh-nghe.module').then(m => m.HoSoHanhNgheModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-98',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/ho-so-cap-nhat-nhan-su-y/ho-so-cap-nhat-nhan-su-y.module').then(m => m.ThuTuc98Module), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-97',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/ho-so-cap-nhat-nhan-su-duoc/ho-so-cap-nhat-nhan-su-duoc.module').then(m => m.ThuTuc97Module), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thu-tuc-hanh-chinh/thu-tuc-mau',
                loadChildren: () => import('./pages/thu-tuc-hanh-chinh/thu-tuc-mau/thu-tuc-mau.module').then(m => m.ThuTucMauModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'truyen-thong-nhan-su',
                loadChildren: () => import('./pages/truyen-thong-nhan-su/truyen-thong-nhan-su.module').then(m => m.TruyenThongNhanSuModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'to-thu-ky',
                loadChildren: () => import('./pages/to-thu-ky/to-thu-ky.module').then(m => m.ToThuKyModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-tai-khoan-co-so',
                loadChildren: () => import('./pages/quan-ly-tai-khoan-co-so/quan-ly-tai-khoan-co-so.module').then(m => m.QuanLyTaiKhoanCoSoModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'chung-chi-hanh-nghe',
                loadChildren: () => import('./pages/chung-chi-hanh-nghe/chung-chi-hanh-nghe.module').then(m => m.ChungChiHanhNgheModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-cong-van',
                loadChildren: () => import('./pages/quan-ly-cong-van/quan-ly-cong-van.module').then(m => m.QuanLyCongVanModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'bao-cao',
                loadChildren: () => import('./pages/bao-cao/bao-cao.module').then(m => m.BaoCaoModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'tra-cuu',
                loadChildren: () => import('./pages/tra-cuu-thong-tin/tra-cuu.module').then(m => m.TraCuuModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'loc-khoa-phong-benh-vien',
                loadChildren: () => import('./pages/loc-khoa-phong-benh-vien/loc-khoa-phong-benh-vien.module').then(m => m.LocKhoaPhongBenhVienModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            { path: '**', redirectTo: '/app/main/dashboard' },
        ],
    },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ]
    ,
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        private router: Router,
    ) {

    }
}
