import { Component, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@delon/theme';

@Component({
    selector: 'layout-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
    constructor(public settingsSrv: SettingsService,) {
    }

    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }
    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }
}
