import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.DanhMucHuyenServiceProxy,
        ApiServiceProxies.DanhMucTinhServiceProxy,
        ApiServiceProxies.DanhMucXaServiceProxy,
        ApiServiceProxies.PhongBanServiceProxy,
        ApiServiceProxies.ComboBaseServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ChuKyServiceProxy,
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,
        ApiServiceProxies.KySoServiceProxy,
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,
        ApiServiceProxies.QuyTrinhKyServiceProxy,
        ApiServiceProxies.TaiKhoanServiceProxy,
        ApiServiceProxies.VanBanServiceProxy,
        ApiServiceProxies.VanBanDenServiceProxy,
        ApiServiceProxies.VanBanChoDuyetServiceProxy,
        ApiServiceProxies.VanBanChoTuVanServiceProxy,
        ApiServiceProxies.VanBanChoPhanCongServiceProxy,
        ApiServiceProxies.VanBanBienSoanServiceProxy,
        ApiServiceProxies.ToTrinhPhoPhongDuyetServiceProxy,
        ApiServiceProxies.ToTrinhTruongPhongDuyetServiceProxy,
        ApiServiceProxies.ToTrinhPhongBanGopYServiceProxy,
        ApiServiceProxies.ToTrinhChuyenVienGopYServiceProxy,
        ApiServiceProxies.ToTrinhChoPhoGiamDocPheDuyetServiceProxy,
        ApiServiceProxies.ToTrinhChoGiamDocPheDuyetServiceProxy,
        ApiServiceProxies.ToTrinhChoBanHanhServiceProxy,
        ApiServiceProxies.ChucVuServiceProxy,
        ApiServiceProxies.CongViecCanXuLyServiceProxy,
        ApiServiceProxies.ThietLapDomainServiceProxy,
        ApiServiceProxies.ToTrinhDaPheDuyetServiceProxy,
        ApiServiceProxies.NgayNghiServiceProxy,
        ApiServiceProxies.LichLamViecServiceProxy,
        ApiServiceProxies.UtilityServiceProxy,
        ApiServiceProxies.FileUploadServiceProxy,
        ApiServiceProxies.DangKyTaiKhoanServiceProxy,
        ApiServiceProxies.QuanLyTaiKhoanCoSoServiceProxy,
        ApiServiceProxies.BaoCaoNhanLucServiceProxy,
        ApiServiceProxies.QuanLyCongVanServiceProxy,
        ApiServiceProxies.CongVanXacMinhHanhNgheServiceProxy,
        ApiServiceProxies.LocKhoaPhongBenhVienServiceProxy,
        //Ký điện tử

        ApiServiceProxies.ToTrinhServiceProxy,
        ApiServiceProxies.ToTrinhXetDuyetServiceProxy,
        ApiServiceProxies.ToTrinhBanHanhServiceProxy,
        ApiServiceProxies.ToTrinhGopYServiceProxy,

        //Dashboard
        ApiServiceProxies.DashboardServiceProxy,

        //quản lý lịch họp
        ApiServiceProxies.LichHopServiceProxy,
        ApiServiceProxies.LichHopMoiServiceProxy,
        ApiServiceProxies.LichHopChoDuyetServiceProxy,
        ApiServiceProxies.LichHopGopYServiceProxy,
        ApiServiceProxies.LichHopTongHopServiceProxy,
        ApiServiceProxies.LichHopKetLuanServiceProxy,

        // dữ liệu sở y tế
        ApiServiceProxies.CoSoCapPhepServiceProxy,
        ApiServiceProxies.CoSoDuocServiceProxy,
        ApiServiceProxies.CoSoNguoiDuocServiceProxy,
        ApiServiceProxies.ThongBaoServiceProxy,
        ApiServiceProxies.ThayDoiThongTinCoSoDuocServiceProxy,
        ApiServiceProxies.ThayDoiThongTinCoSoYServiceProxy,
        ApiServiceProxies.ThongBaoServiceProxy,

        //Danh mục chuyên môn
        ApiServiceProxies.ChucDanhNgheNghiepServiceProxy,
        ApiServiceProxies.CoSoCongLapServiceProxy,
        ApiServiceProxies.HinhThucToChucServiceProxy,
        ApiServiceProxies.PhamViHoatDongCoSoServiceProxy,
        ApiServiceProxies.PhamViHoatDongNguoiServiceProxy,
        ApiServiceProxies.VanBangChuyenMonServiceProxy,
        ApiServiceProxies.CoSoDuDieuKienServiceProxy,
        ApiServiceProxies.ThoiGianLamViecTrongNgayServiceProxy,
        ApiServiceProxies.KhoaPhongServiceProxy,
        ApiServiceProxies.HoSo01ServiceProxy,
        ApiServiceProxies.ChungChiHanhNgheServiceProxy,
        ApiServiceProxies.ChungChiHanhNgheDuocServiceProxy,
        ApiServiceProxies.LyDoThuongGapKhiDuyetServiceProxy,
        // Hồ sơ thủ tục
        ApiServiceProxies.HoSo01CommonServiceProxy,
        ApiServiceProxies.TaoHoSo01ServiceProxy,
        ApiServiceProxies.TraCuuHoSo01ServiceProxy,
        ApiServiceProxies.PhanCongHoSo01ServiceProxy,
        ApiServiceProxies.XuLyHoSo01ServiceProxy,
        ApiServiceProxies.ThamDinhHoSo01ServiceProxy,
        ApiServiceProxies.HoanThienHoSo01ServiceProxy,
        ApiServiceProxies.InVaTrinhHoSo01ServiceProxy,
        ApiServiceProxies.DuyetHoSoKhongCap01ServiceProxy,
        ApiServiceProxies.DuyetHoSoDongYCap01ServiceProxy,
        ApiServiceProxies.CapNhatTaiLieuHoSo01ServiceProxy,

        // tổ thư ký
        ApiServiceProxies.ToThuKyServiceProxy,

        //Thư viện văn bản
        ApiServiceProxies.CongBoVanBanServiceProxy,
        ApiServiceProxies.TraCuuVanBanCongBoServiceProxy,
        ApiServiceProxies.QuanLyGuiMailServiceProxy,

        //Thủ tục 98
        ApiServiceProxies.TaoHoSo98ServiceProxy,
        ApiServiceProxies.HoSo98CommonServiceProxy,
        ApiServiceProxies.PhanCongHoSo98ServiceProxy,
        ApiServiceProxies.XuLyHoSo98ServiceProxy,
        ApiServiceProxies.TruongPhongDuyetHoSo98ServiceProxy,
        ApiServiceProxies.GiamDocDuyetHoSo98ServiceProxy,
        ApiServiceProxies.TraCuuHoSo98ServiceProxy,
        ApiServiceProxies.TiepNhanHoSo98ServiceProxy,
        ApiServiceProxies.PhoPhongDuyetHoSo98ServiceProxy,
        ApiServiceProxies.PhoGiamDocDuyetHoSo98ServiceProxy,

        // Thủ tục 97
        ApiServiceProxies.TaoHoSo97ServiceProxy,
        ApiServiceProxies.HoSo97CommonServiceProxy,
        ApiServiceProxies.PhanCongHoSo97ServiceProxy,
        ApiServiceProxies.XuLyHoSo97ServiceProxy,
        ApiServiceProxies.TruongPhongDuyetHoSo97ServiceProxy,
        ApiServiceProxies.GiamDocDuyetHoSo97ServiceProxy,
        ApiServiceProxies.TraCuuHoSo97ServiceProxy,
        ApiServiceProxies.TiepNhanHoSo97ServiceProxy,
        ApiServiceProxies.PhoPhongDuyetHoSo97ServiceProxy,
        ApiServiceProxies.PhoGiamDocDuyetHoSo97ServiceProxy,
        ApiServiceProxies.BaiKiemTraNguoiHanhNgheHoSo97ServiceProxy,

        //mẫu hồ sơ
        ApiServiceProxies.TaoHoSo99ServiceProxy,
        ApiServiceProxies.HoSo99CommonServiceProxy,
        ApiServiceProxies.PhanCongHoSo99ServiceProxy,
        ApiServiceProxies.XuLyHoSo99ServiceProxy,
        ApiServiceProxies.DuyetHoSo99ServiceProxy,
        ApiServiceProxies.TraCuuHoSo99ServiceProxy,

        // tra cứu
        ApiServiceProxies.TraCuuDuLieuCoSoCapPhepServiceProxy,
        ApiServiceProxies.TraCuuDuLieuChungChiHanhNgheServiceProxy,

        // thủ tục cập nhất nhân sự
        ApiServiceProxies.TruyenThongCapNhatNhanSuServiceProxy,
        //ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.DuLieuCuServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule { }
