<div class="top-nav">
    <!-- <nz-switch [ngModel]="collapsed" (ngModelChange)="toggleCollapsed()"></nz-switch> -->
    <sidebar-nav #widgetsContent></sidebar-nav>
    <div class="nav_button_left" style="font-size: 25px;">
        <a>
            <span nz-icon nzType="left" nzTheme="outline"></span>
        </a>
    </div>
    <div class="nav_button_right" style="font-size: 25px;">
        <a>
            <span nz-icon nzType="right" nzTheme="outline"></span>
        </a>
    </div>
</div>
